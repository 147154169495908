export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

/**
 * From T, pick a set of properties whose values are in the type V
 */
export type PickByValue<T, V> = keyof { [P in keyof T as T[P] extends V ? P : never]: P };

export type Override<T, U> = Omit<T, keyof U> & U;

export type One<T> = { [K in keyof T]: K }[keyof T];

export type Flags<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export type PartialRequired<T, TRequired extends keyof T = keyof T> = Partial<Pick<T, Exclude<keyof T, TRequired>>> &
  Required<Pick<T, TRequired>>;

export type PartialPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

export type Unpromise<T extends Promise<unknown>> = T extends Promise<infer U> ? U : never;

export interface JsonMap extends Record<string, JsonPrimitive | JsonArray | JsonMap> {}
export interface JsonArray extends Array<JsonPrimitive | JsonArray | JsonMap> {}

export type JsonPrimitive = string | number | boolean | null;
export type Json = JsonPrimitive | JsonMap | JsonArray;

export type ViewAsVisibility = "private" | "public";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyDefined = Omit<any, undefined | null>;

// Guards

export function notUndefined<T>(x: T | undefined): x is T {
  return undefined !== x;
}

export function notNull<T>(x: T | null): x is T {
  return null !== x;
}

export function notEmpty<T>(x?: T | null): x is T {
  return notUndefined(x) && notNull(x) && (!Array.isArray(x) || !!x.length);
}

export function notFalsy<T>(x: T | null | false | [] | "" | undefined): x is T {
  return !!x;
}

export function isType<T>(x: T | undefined, fn: (x: T) => boolean): x is T {
  return notUndefined(x) && fn(x);
}

export function isSub<T extends P, P>(x: P, fn: (x: P) => boolean): x is T {
  return notNull(x) && notUndefined(x) && fn(x);
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
